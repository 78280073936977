<template>
    <template v-if="filteredBanks.length > 0">
        <v-col v-for="(bank, index) in filteredBanks" :key="bank.id" cols="12" class="pa-1">
            <v-lazy>
                <v-card elevation="3">
                    <v-card-actions>
                        <v-row justify="end" class="px-2 text-overline" align="center">
                            <v-col class="text-left" cols="6">
                                {{ bank.tag }}
                            </v-col>
                            <v-col class="text-right" cols="6">
                                <v-btn :append-icon="bank.bank_show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                                    color="indigo-darken-2" variant="text" @click="bank.bank_show = !bank.bank_show">
                                    Show
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                    <v-divider class="mx-4"></v-divider>
                    <v-expand-transition>
                        <div v-show="bank.bank_show">
                            <v-container class="pa-3 pt-0 pb-3">
                                <v-data-table-virtual fixed-header height="700" :headers="headers" :items="bank.sim"
                                    :sort-by="[{ key: 'sim_name', order: 'asc' }]" density="compact" :items-per-page="-1"
                                    class="elevation-1" :loading="loading" loading-text="Loading...">

                                    <template v-slot:top>
                                        <v-dialog v-model="dialog" hide-overlay max-width="500px">
                                            <v-card>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field v-model="editedItem.drops" label="Name"
                                                                    density="compact"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="6">
                                                                <v-text-field v-model="editedItem.fixed_simnum"
                                                                    label="Phone number" density="compact"></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                                <template v-slot:actions>
                                                    <v-btn class="flex-grow" color="indigo-darken-2" variant="text" @click="close">Cancel</v-btn>
                                                    <v-btn class="flex-grow-1" color="indigo-darken-2" variant="tonal" @click="save">Save</v-btn>
                                                </template>
                                            </v-card>
                                        </v-dialog>
                                    </template>

                                    <template v-slot:[`item.sim_login`]="{ value }">
                                        <v-icon :color="value == '11' ? 'green-lighten-2' : 'red-darken-1'" icon="mdi-circle-medium"></v-icon>
                                    </template>

                                    <template v-slot:[`item.bank_name`]>
                                        <span style="white-space:nowrap">{{ bank.tag }}</span>
                                    </template>

                                    <template v-slot:[`item.sim_name`]="{ value }">
                                        <span>{{ value.toString().slice(-3) }}</span>
                                    </template>

                                    <template v-slot:[`item.drops`]="{ value }">
                                        <span>{{ value }}</span>
                                    </template>

                                    <template v-slot:[`item.fixed_simnum`]="{ value }">
                                        <span>{{ value }}</span>
                                    </template>

                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-row no-gutters>
                                            <v-col cols="6">
                                                <v-icon size="small" class="me-2" @click="editItem(index, item)">mdi-pencil</v-icon>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-icon size="small" class="me-2" @click="unlink(index, item)">mdi-window-close</v-icon>
                                            </v-col>
                                        </v-row>
                                    </template>

                                    <template v-slot:no-data>
                                        <span v-if="!loading && (!bank.sim || bank.sim.length === 0)">No data available</span>
                                    </template>

                                </v-data-table-virtual>
                            </v-container>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-lazy>
        </v-col>
    </template>
    <v-col v-else cols="12" class="pa-1">
        <v-card elevation="3" class="text-center">
            <v-card-text>
                <v-icon size="48" color="grey lighten-1">mdi-database-off-outline</v-icon>
                <div class="grey--text text--darken-1 mt-2">No data available</div>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SimTable',
    data() {
        return {
            dialog: false,
            loading: true,
            headers: [
                { title: '', align: 'start', key: 'sim_login', sortable: true, width: '65px' },
                { title: 'Bank', key: 'bank_name', sortable: false, width: '85px' },
                { title: 'Cell', key: 'sim_name', sortable: true, width: 'auto' },
                { title: 'Name', key: 'drops', sortable: true, width: 'auto' },
                { title: 'Number', key: 'fixed_simnum', sortable: false, width: 'auto' },
                { title: 'Action', key: 'actions', sortable: false, width: '85px' },
            ],
            editedItem: {
                drops: '',
                fixed_simnum: '',
            },
            defaultItem: {
                drops: '',
                fixed_simnum: '',
            },
            editedIndex: -1,
            bankIndex: -1,
        };
    },
    computed: {
        ...mapState({
            bank: state => state.bank.bank_list,
        }),
        filteredBanks() {
            return this.bank && this.bank.length > 0 ? this.bank : [];
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions({
            getAllBanks: 'bank/getAll',
            linkBank: 'bank/link',
            unlinkBank: 'bank/unlink',
        }),
        async initialize() {
            this.loading = true;
            await this.getAllBanks();
            this.loading = false;
        },
        editItem(index, item) {
            this.bankIndex = index;
            this.editedIndex = this.bank[this.bankIndex].sim.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        close() {
            this.dialog = false;
            this.editedItem = Object.assign({}, this.defaultItem);
            this.bankIndex = -1;
            this.editedIndex = -1;
        },
        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.bank[this.bankIndex].sim[this.editedIndex], this.editedItem);
                this.linkBank({
                    id: this.bank[this.bankIndex].sim[this.editedIndex].id,
                    fixed_simnum: this.editedItem.fixed_simnum,
                    drops: this.editedItem.drops,
                });
            }
            this.close();
        },
        unlink(index, item) {
            const empty = {
                drops: '',
                fixed_simnum: '',
            };
            this.bankIndex = index;
            this.editedIndex = this.bank[this.bankIndex].sim.indexOf(item);
            Object.assign(this.bank[this.bankIndex].sim[this.editedIndex], empty);
            this.unlinkBank({ id: item.id });
        },
    },
};
</script>

<style scoped></style>
