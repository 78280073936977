<template>
  <v-container>
    <!-- Карточки для каждой команды -->
    <v-card v-for="team in teams" :key="team.id" elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
      <!-- Заголовок карточки с названием команды -->
      <v-card-title class="text-overline">
        <v-row justify="start" align="center">
          <v-col class="text-left pb-2" cols="6">
            {{ team.name }}
          </v-col>
          <v-col class="text-right pb-2" cols="6">
            <v-btn color="red-darken-4" variant="text" @click="deleteTeam(team)">
              Delete Team
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider class="mx-4"></v-divider>

      <v-row>
        <!-- Дерево элементов для команды -->
        <v-col>
          <v-card-text>
            <v-treeview
              v-model:selected="selections[team.id]"
              :items="items"
              :load-children="loadChildren"
              :select-strategy="selectionType"
              false-icon="mdi-bookmark-outline"
              indeterminate-icon="mdi-bookmark-minus"
              true-icon="mdi-bookmark"
              item-title="name"
              item-value="id"
              return-object
              selectable
              multiple
              open-on-click
              @update:selected="updateSelections(team)"
            >
            </v-treeview>
          </v-card-text>
        </v-col>

        <v-divider class="my-5" vertical></v-divider>

        <!-- Отображение выбранных элементов для команды -->
        <v-col cols="12" md="6">
          <v-card-text>
            <div v-if="selections[team.id]?.length === 0" class="text-button text-h6 font-weight-light text-grey pa-4 text-center">
              Select items
            </div>

            <div v-else class="chip-container">
              <v-chip
                v-for="(node, i) in selections[team.id]"
                :key="i"
                :text="node.name"
                class="ma-1"
                color="grey"
                prepend-icon="mdi-cellphone"
                size="small"
              ></v-chip>
            </div>
          </v-card-text>
        </v-col>
      </v-row>

      <v-divider class="mx-4"></v-divider>

      <!-- Кнопка сохранения -->
      <v-card-actions>
        <v-btn class="flex-grow-1" text="Save" color="indigo-darken-2" variant="tonal" @click="saveSelections(team)">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Snackbar для уведомлений -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3000">
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { VTreeview } from 'vuetify/labs/VTreeview';
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
  name: "TeamsView",
  components: {
    VTreeview,
  },
  data() {
    return {
      selectionType: 'classic',
      teams: [],
      selections: {},
      items: [
        {
          id: 0,
          name: 'banks',
          children: [],
          load: false,
        },
      ],
      snackbar: {
        show: false,
        text: '',
        color: 'grey darken-1',
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  methods: {
    resetSelection() {
      this.teams.forEach(team => {
        this.selections[team.id] = [];
      });
    },
    async loadChildren(item) {
      if (item.load) return;

      await new Promise(resolve => requestAnimationFrame(resolve));

      try {
        const data = await this.$store.dispatch('bank/getAll');
        item.children = data.map(bank => ({
          id: bank.id,
          name: bank.tag,
          children: bank.sim.map(sim => ({
            id: sim.id,
            name: `${sim.bank_name}[${sim.sim_name.toString().slice(-3)}] ${sim.fixed_simnum}`,
          })),
        }));
        item.load = true;
      } catch (error) {
        console.error('Loading bank error:', error);
      }
    },
    async loadTeams() {
      try {
        const teamsData = await this.$store.dispatch("teams/getAll");
        this.teams = teamsData;

        // Инициализация выбранных SIM для каждой команды
        this.teams.forEach(team => {
          this.selections[team.id] = team.sims?.map(sim => ({
            id: sim.id,
            name: `${sim.bank_name}[${sim.sim_name.toString().slice(-3)}] ${sim.fixed_simnum}`
          })) || [];
        });
      } catch (error) {
        console.error("Failed to load teams:", error);
      }
    },
    updateSelections(team) {
      // Обновляет `selections` без дублирования данных
      const currentSelections = this.selections[team.id];
      const uniqueSelections = new Map();

      currentSelections.forEach(item => {
        uniqueSelections.set(item.id, item);
      });

      this.selections[team.id] = Array.from(uniqueSelections.values());
    },
    async saveSelections(team) {
      try {
        const selectedSIMs = this.selections[team.id].map(sim => ({
          id: sim.id,
          name: sim.name,
        }));

        await this.$store.dispatch("teams/saveSelection", { teamId: team.id, selectedSIMs });
        this.showSnackbar(`Selections for team ${team.name} saved successfully!`);
      } catch (error) {
        console.error("Failed to save selections:", error);
        this.showSnackbar(`Failed to save selections for team ${team.name}.`, 'error');
      }
    },
    async deleteTeam(team) {
      try {
        await this.$store.dispatch("teams/delete", team.name);
        this.teams = this.teams.filter(t => t.name !== team.name);
        this.showSnackbar(`Team "${team.name}" deleted successfully!`);
      } catch (error) {
        console.error("Failed to delete team:", error);
        this.showSnackbar(`Failed to delete team "${team.name}".`, 'error');
      }
    },
    showSnackbar(text, status = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = status === 'success' ? 'green-lighten-1' : 'red darken-1';
      this.snackbar.show = true;
    }
  },
  async created() {
    await this.loadTeams();

    UserService.getManagerBoard().then(
      response => {
        console.log(response);
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  },
};
</script>

<style scoped>
.chip-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
