// store/modules/messages.module.js

import messageService from "@/services/message.service";

const storagedMessages = JSON.parse(localStorage.getItem('messages') || '[]');

const initialState = {
  logs: storagedMessages, // Загруженные логи из localStorage при инициализации
};

export const logs = {
  namespaced: true,
  state: initialState,
  actions: {
    async getLogs({ commit }, filters) {
      try {
        const messages = await messageService.getLogs(filters);
        commit('getLogSuccess', messages);
        return Promise.resolve(messages);
      } catch (error) {
        commit('getLogFailure');
        return Promise.reject(error);
      }
    },
  },
  mutations: {
    getLogSuccess(state, messages) {
      state.logs = messages;
      localStorage.setItem('messages', JSON.stringify(messages)); // Сохраняем логи в localStorage
    },
    getLogFailure() {
      console.error('Failed to fetch logs');
    },
  },
  getters: {
    allLogs: (state) => state.logs, // Геттер для доступа к логам
  },
};
