import BACKEND_URL from './api.service.js';

class UserService {
  getUserMessages(username) {
    return BACKEND_URL.post(`/api/v1/get/messages`, {
      username: username,
    })
      .then((response) => {
        //console.log('message service ', response.data)
        return response?.data?.messages.reverse()
      });
  }
  async getLogs(filters) {
    const response = await BACKEND_URL.post('/api/v1/message/logs', filters);
    return response?.data?.messages;
}
}

export default new UserService();