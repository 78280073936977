<template>
    <v-container>
        <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
            <v-card-title class="text-button">Select Filters</v-card-title>
            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-chip-group v-model="selectedChips" column multiple class="mb-4">
                    <v-chip v-for="chip in chips" :key="chip.value" :value="chip.value" outlined>{{ chip.label }}</v-chip>
                </v-chip-group>

                <v-row dense>
                    <v-col cols="12" sm="6">
                        <v-col v-if="selectedChips.includes('username')">
                            <v-text-field density="compact" v-model="filters.username" label="Username" dense clearable outlined></v-text-field>
                        </v-col>

                        <v-col v-if="selectedChips.includes('simnum')">
                            <v-text-field density="compact" v-model="filters.simnum" label="Sim Number" dense clearable outlined></v-text-field>
                        </v-col>

                        <v-col v-if="selectedChips.includes('drops')">
                            <v-text-field density="compact" v-model="filters.drops" label="Name" dense clearable outlined></v-text-field>
                        </v-col>

                        <v-col v-if="selectedChips.includes('text')">
                            <v-text-field density="compact" v-model="filters.text" label="Text" dense clearable outlined></v-text-field>
                        </v-col>

                        <v-col v-if="selectedChips.includes('srcnum')">
                            <v-text-field density="compact" v-model="filters.srcnum" label="Source Number" dense clearable outlined></v-text-field>
                        </v-col>
                    </v-col>

                    <v-col v-if="selectedChips.includes('dateRange')" cols="12" sm="6">
                        <v-date-picker v-model="filters.dateRange" multiple="range" show-adjacent-months></v-date-picker>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-actions>
                <v-btn color="primary" class="mx-auto" @click="fetchLogsWithFilters">Submit</v-btn>
            </v-card-actions>
        </v-card>

        <v-card v-if="allLogs && allLogs.length > 0" elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
            <v-card-title class="text-button">Logs</v-card-title>
            <v-divider class="mx-4"></v-divider>

            <v-card-text>
                <v-data-table-virtual :headers="headers" :items="allLogs" :item-height="50" height="400">
                    <template v-slot:[`item.username`]="{ value }">
                        <span>{{ value }}</span>
                    </template>
                    <template v-slot:[`item.drops`]="{ value }">
                        <span>{{ value }}</span>
                    </template>
                    <template v-slot:[`item.simnum`]="{ value }">
                        <span>{{ value }}</span>
                    </template>
                    <template v-slot:[`item.srcnum`]="{ value }">
                        <span>{{ value }}</span>
                    </template>
                    <template v-slot:[`item.text`]="{ value }">
                        <span>{{ value }}</span>
                    </template>
                    <template v-slot:[`item.createdAt`]="{ value }">
                        <span>{{ timeFormat(value) }}</span>
                    </template>
                </v-data-table-virtual>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
    data() {
        return {
            selectedChips: [],
            chips: [
                { label: "Username", value: "username" },
                { label: "Sim Number", value: "simnum" },
                { label: "Name", value: "drops" },
                { label: "Text", value: "text" },
                { label: "Source Number", value: "srcnum" },
                { label: "Date Range", value: "dateRange" },
            ],
            headers: [
                { title: "Username", align: 'start', key: 'user_id', sortable: true, width: '65px' },
                { title: "Sim Number", key: 'simnum', sortable: true, width: '85px' },
                { title: "Name", key: 'drops', sortable: true, width: 'auto' },
                { title: "Source Number", key: 'srcnum', sortable: true, width: 'auto' },
                { title: "Text", key: 'message', sortable: false, width: 'auto' },
                { title: 'Date', key: 'createdAt', sortable: true, width: '85px' },
            ],
            filters: {
                username: "",
                simnum: "",
                drops: "",
                text: "",
                srcnum: "",
                dateRange: [],
            },
        };
    },
    computed: {
        ...mapGetters('logs', ['allLogs']),
        formatDateRange() {
            if (!this.filters.dateRange || this.filters.dateRange.length === 0) return "";
            if (this.filters.dateRange.length === 1) return this.filters.dateRange[0].toLocaleDateString();
            return `${this.filters.dateRange[0].toLocaleDateString()} - ${this.filters.dateRange[this.filters.dateRange.length - 1].toLocaleDateString()}`;
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        UserService.getAdminBoard().then(
            (response) => console.log(response),
            (error) => {
                this.content = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        ...mapActions('logs', ['getLogs']),
        async fetchLogsWithFilters() {
            const { dateRange, ...otherFilters } = this.filters;
            const filters = {
                ...otherFilters,
                startDate: dateRange[0] ? new Date(dateRange[0]).toISOString() : null,
                endDate: dateRange[dateRange.length - 1] ? new Date(dateRange[dateRange.length - 1]).toISOString() : null,
            };
            await this.getLogs(filters);
        },
        timeFormat(time) {
            return new Date(time).toLocaleString('ru-RU');
        },
    },
};
</script>

<style scoped>
.v-menu__content {
    z-index: 300 !important;
}
</style>
