<template>
    <v-col cols="12" class="pa-1">
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
           
<p>чтобы получить смс необходимо:<br>
1) запустить сессию используя команду<br>
/start [номер телефона(id дропа) без скобок/тире/пробелов]</p>
<br>
<p>пример:<br>
/start 48509879423<br>
ответ системы:<br>
request 48509879423 <br>
session with id 86 created, wait for activation<br>
означает, что сессия успешно создана</p>
<br>
<p>2) необходимо дождаться активации номера<br>
ответ системы:<br>
48509879423 <br>
session with id 86 active<br>
означает, что номер активен<br>
теперь вы можете получать смс на этот номер</p>
<br>
<p>3) завершение сессии:<br>
/stop [номер телефона(id дропа) без скобок/тире/пробелов]<br>
либо<br>
/stopall остановит все запущенные вами сессии</p>
<br>
<p>пример:<br>
/stop 48509879423<br>
/stopall <br>
</p>
<br>
<p>4) Дополнительно:<br>
ответ системы:<br>
request 48509879423<br>
session with id 86 queued, wait your turn<br>
означает, что все свободные слоты заняты.<br>
сим запустится сразу после того, как один из слотов освободится</p>
<br>
<p>ответ системы:<br>
session with id: 86<br>
number: 48509879423<br>
incoming call: 48283462987<br>
означает что в данный момент на вашу симкарту<br>
совершается входяший звонок с номера 48283462987</p>
<br>
<p>команда: /reload 48509879423<br>
полностью перезагрузит gsm модуль и вызовет повторную активацию сим<br>
без остановки сессии, это займет 1-2 минуты<br>
поможет разбудить зависшую сессиюю, если смс долго не поступают</p>
<br>
<!-- <p>команда: /unq 48509879423<br>
удалит сессию из очереди на активацию</p>
<br>
<p>команда: /unqall<br>
удалит все сессии из очереди на активацию</p> -->

        </v-card-text>



        <v-expand-transition>
            <div v-show="true">
                <v-container class="px-3 pt-0 pb-3">

                </v-container>
            </div>
        </v-expand-transition>


    </v-col>
</template>

<script>

export default {
    name: 'userGuide',
    props: {

    },
    setup() {

    },
    data: () => ({
        data: "",
    }),
    computed: {

    },

    watch: {

    },

    created() {

    },

    methods: {

    },

};
</script>

<style scoped></style>