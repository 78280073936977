<template>
  <v-container>
    <!-- Создание пользователя -->
    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
      <v-row dense>
        <v-col cols="12">
          <v-card elevation="3">
            <v-card-title class="text-button">
              <v-row justify="end">
                <v-col> Create user </v-col>
              </v-row>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-col cols="12" class="px-6">
              <v-form fast-fail @submit.prevent="handleSignup" v-model="isUserFormValid">
                <div class="text-subtitle-1 text-medium-emphasis">Account</div>
                <v-text-field autocomplete="username" density="compact" placeholder="Username"
                  prepend-inner-icon="mdi-at" variant="outlined" v-model="username"
                  :rules="usernameRules"></v-text-field>

                <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                  Password
                </div>
                <v-text-field autocomplete="current-password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="visible ? 'text' : 'password'" density="compact" placeholder="Enter your password"
                  prepend-inner-icon="mdi-lock-outline" variant="outlined" v-model="password" :rules="passwordRules"
                  @click:append-inner="visible = !visible"></v-text-field>
              </v-form>
            </v-col>

            <v-card-actions class="justify-center">
              <v-btn :disabled="!isUserFormValid" @click="handleSignup" variant="text" color="indigo-darken-2">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Создание команды -->
    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
      <v-row dense>
        <v-col cols="12">
          <v-card elevation="3">
            <v-card-title class="text-button">
              <v-row justify="end">
                <v-col> Create team </v-col>
              </v-row>
            </v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-col cols="12" class="px-6">
              <v-form fast-fail @submit.prevent="handleCreateTeam" v-model="isTeamFormValid">
                <div class="text-subtitle-1 text-medium-emphasis">Team</div>
                <v-text-field autocomplete="team" density="compact" placeholder="Team name"
                  prepend-inner-icon="mdi-format-list-group" variant="outlined" v-model="team"
                  :rules="teamNameRules"></v-text-field>
              </v-form>
            </v-col>

            <v-card-actions class="justify-center">
              <v-btn :disabled="!isTeamFormValid" @click="handleCreateTeam" variant="text" color="indigo-darken-2">
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Snackbar для сообщений -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3000">
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
  name: "AdminView",
  data() {
    return {
      visible: false,
      isUserFormValid: false,
      isTeamFormValid: false,
      username: "",
      password: "",
      team: "",
      snackbar: {
        show: false,
        text: '',
        color: 'grey darken-1' // Нейтральный цвет по умолчанию
      },
      usernameRules: [
        value => !!value || "Username can't be empty."
      ],
      passwordRules: [
        value => (value && value.length >= 6) || "Password must be at least 6 characters."
      ],
      teamNameRules: [
        value => !!value || "Team name can't be empty."
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    UserService.getAdminBoard().then(
      response => {
        console.log(response);
      },
      error => {
        this.showSnackbar(
          error.response?.data?.message || error.message || "Failed to load admin board.",
          'error'
        );

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  },
  methods: {
    handleSignup() {
      if (!this.isUserFormValid) return;

      const user = {
        telegram: this.username,
        username: this.username,
        password: this.password,
        roles: ["user"],
        teams: ["none"]
      };

      this.$store.dispatch("auth/createUser", user).then(
        response => {
          this.showSnackbar(response.message || "User created successfully!", 'success');
        },
        error => {
          this.showSnackbar(error.response?.data?.message || "Failed to create user.", 'error');
        }
      );
    },
    handleCreateTeam() {
      if (!this.isTeamFormValid) return;

      this.$store.dispatch("teams/add", this.team).then(
        response => {
          this.showSnackbar(`Team "${this.team}" creation status: ${response.message}!`, 'success');
          //this.team = ""; // Очистка поля после успешного создания
        },
        error => {
          this.showSnackbar(error.response?.data?.message || `Failed to create team "${this.team}".`, 'error');
        }
      );
    },
    showSnackbar(text, status = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = status === 'success' ? 'green-lighten-1' : 'red darken-1';
      this.snackbar.show = true;
    }
  },
};
</script>

<style scoped></style>
