<template>
    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
        <!-- Форма для запроса сессии -->
        <v-card-title class="text-button"> Request session </v-card-title>
        <v-form fast-fail @submit.prevent>
            <v-card-text>
                <v-text-field v-model="requestSession" label="Number or drop id" :loading="loading" density="compact"
                    variant="outlined" single-line hide-details>
                    <template v-slot:append-inner>
                        <v-btn :loading="loading" type="submit" color="success" variant="text"
                            @click="handleRequest">Request</v-btn>
                    </template>
                </v-text-field>
            </v-card-text>
        </v-form>

        <v-snackbar v-model="snackbar.show" :timeout="3000" top :color="snackbar.color" elevation="6">
            {{ snackbar.text }}
            <v-progress-linear v-if="loading" indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-snackbar>
    </v-card>

    <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
        <!-- Поиск и заголовок для сообщений -->
        <v-card-title class="text-button"> Messages </v-card-title>
        <v-card-text class="px-4 pb-0">
            <v-text-field label="Search" v-model="search" density="compact" variant="outlined" single-line hide-details
                @input="debouncedSearch"></v-text-field>
        </v-card-text>

        <v-card-actions class="px-4">
            <div class="d-flex align-center">Latest 100 messages: </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <v-btn :append-icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="indigo-darken-2"
                    variant="text" @click="toggleMessages">Show</v-btn>
            </div>
        </v-card-actions>

        <v-expand-transition>
            <div v-show="show">
                <v-container fluid>
                    <!-- Виртуальный скроллинг для списка сообщений -->
                    <v-virtual-scroll :items="filtredMessages" item-height="100">
                        <template #default="{ item: message }">
                            <v-card elevation="2" class="mb-2">
                                <v-card-title class="text-button">
                                    {{ message.simnum }} {{ message.drops ? `[${message.drops}]` : '' }}
                                </v-card-title>
                                <v-divider class="mx-4"></v-divider>
                                <v-card-subtitle class="mt-1">From: {{ message.srcnum }}</v-card-subtitle>
                                <v-card-text>
                                    <p class="py-2">{{ message.message }}</p>
                                    <v-divider></v-divider>
                                    <p class="py-2">Received at: {{ timeFormat(message.createdAt) }}</p>
                                </v-card-text>
                            </v-card>
                        </template>
                    </v-virtual-scroll>
                </v-container>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";
import debounce from 'lodash/debounce';

export default {
    name: 'SmsView',
    setup() {
        const store = useStore();
        const user = computed(() => store.state.auth.user);
        const messages = ref([]);
        const show = ref(false);

        return {
            user,
            messages,
            show,
        };
    },
    mounted() {
        UserService.getUserBoard().then(
            (response) => console.log(response),
            (error) => {
                this.content = (error.response && error.response.data && error.response.data.message) ||
                    error.message || error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },

    data() {
        return {
            search: '',
            requestSession: '',
            loading: false,
            snackbar: {
                show: false,
                text: '',
                color: 'grey darken-1', // Нейтральный цвет по умолчанию
            },
        };
    },
    computed: {
        filtredMessages() {
            const search = this.search.toLowerCase();
            if (!search) return this.messages;
            return this.messages.filter(item => item.simnum?.toLowerCase().includes(search));
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },

    },
    methods: {
        debouncedSearch: debounce(function () {
            this.filtredMessages;
        }, 300), // Дебаунс для поиска

        timeFormat(time) {
            return new Date(time).toLocaleString('ru-RU');
        },
        showSnackbar(text, status = 'success') {
            this.snackbar.text = text;
            this.snackbar.color = status === 'success' ? 'green-lighten-1' : 'red darken-1';
            this.snackbar.show = true;
        },
        handleRequest() {
            this.loading = true;
            const data = {
                username: this.currentUser.username,
                tg_userid: this.currentUser.tg_userid,
                payload: this.requestSession,
            };
            this.$store.dispatch("sessions/request", data).then(
                (response) => {
                    this.showSnackbar(response.message, 'success');
                    this.loading = false;
                },
                (error) => {
                    this.showSnackbar((error.response && error.response.data && error.response.data.message) ||
                        error.message || error.toString(), 'error');
                    this.loading = false;
                }
            );
        },
        loadMessages() {
            this.loading = true;
            this.$store.dispatch("messages/getAll", this.currentUser.username).then(() => {
                this.messages = this.$store.state.messages.list;
                this.loading = false;
            });
        },
        toggleMessages() {
            this.show = !this.show;
            if (this.show && this.messages.length === 0) {
                this.loadMessages();
            }
        },
    },
};
</script>

<style scoped>
.user-list {
    max-height: 600px;
    overflow-y: auto;
}
</style>