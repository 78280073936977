<template>
  <!-- <v-container> -->
  <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
    <v-text-field ref="search" v-model="search" hide-details label="Search" single-line></v-text-field>
    <v-row dense>
      <v-col cols="12">
        <v-progress-linear :active="load(this.users)" :indeterminate="loading" bottom
          color="indigo-darken-4"></v-progress-linear>
      </v-col>
      <v-col v-for="user in filtredUsers" :key="user.username" cols="12">
        <v-card elevation="3">

          <v-card-title class="text-overline">
            <v-row justify="end" align="center">
              <v-col class="text-left pb-2" cols="6">
                {{ user.username }}
              </v-col>
              <v-col class="text-right pb-2" cols="6">
                <v-btn :append-icon="user.show ? 'mdi-chevron-up' : 'mdi-chevron-down'" color="indigo-darken-2"
                  variant="text" @click="user.show = !user.show">
                  Show profile
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider class="mx-4"></v-divider>

          <v-expand-transition>
            <div v-show="user.show">
              <v-card-text hide-details class="pt-0">
                <v-row justify="end" align="center">
                  <v-col class="text-left pb-2" cols="6">
                    <v-switch v-model="user.enable" color="purple" label="Enable" hide-details></v-switch>
                  </v-col>
                  <v-col class="text-right pb-2" cols="6">
                    <v-btn color="red-darken-4" variant="text" @click="deleteUser(user.username)">
                      Delete User
                    </v-btn>
                  </v-col>
                </v-row>

                <h3 class="mb-1">Choose roles</h3>
                <v-chip-group class="mb-1 mt-1" v-model="user.roles" mandatory column multiple
                  color="deep-purple-accent-4">
                  <v-chip hide-details v-for="role in allRoles" :key="role.name" cols="12" filter variant="outlined"
                    :text="role.name" :value="role.name">
                  </v-chip>
                </v-chip-group>

                <h3 class="mb-1">Choose teams</h3>
                <v-chip-group class="mb-1 mt-1" v-model="user.teams" mandatory column multiple
                  color="deep-purple-accent-4">
                  <v-chip hide-details v-for="team in allTeams" :key="team.name" cols="12" filter variant="outlined"
                    :text="team.name" :value="team.name">
                  </v-chip>
                </v-chip-group>

              </v-card-text>
            </div>
          </v-expand-transition>

          <v-card-actions class="justify-center">
            <v-btn block variant="tonal" color="indigo-darken-2" @click="saveUser(user)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Snackbar для уведомлений -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3000">
      {{ snackbar.text }}
    </v-snackbar>
  </v-card>
  <!-- </v-container> -->
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
  name: "UsersView",
  setup() {
    const store = useStore();

    store.dispatch("users/getAll");
    store.dispatch("roles/getAll");
    store.dispatch("teams/getAll");

    const users = computed(() => store.state.users.user_list);
    const allRoles = computed(() => store.state.roles.role_list);
    const allTeams = computed(() => store.state.teams.team_list);
    return {
      users,
      allRoles,
      allTeams,
    };
  },
  mounted() {
    UserService.getAdminBoard().then(
      response => console.log(response),
      error => {
        this.content = error?.response?.data?.message || error.message || error.toString();

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  },
  data() {
    return {
      selected: [],
      loading: false,
      content: "",
      search: "",
      snackbar: {
        show: false,
        text: '',
        color: 'grey darken-1', // Цвет по умолчанию
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    filtredUsers() {
      const search = this.search.toLowerCase();

      if (!search) return this.users;

      return this.users.filter(item => item.username.toLowerCase().includes(search));
    },
  },
  methods: {
    load(val) {
      if (!val) this.loading = true;
      setTimeout(() => (this.loading = false), 1000);
    },
    saveUser(user) {
      const payload = {
        username: user.username,
        enable: user.enable,
        roles: user.roles,
        teams: user.teams,
      };
      this.$store.dispatch("users/update", payload)
        .then(() => this.showSnackbar(`User ${user.username} saved successfully!`, 'success'))
        .catch(() => this.showSnackbar(`Failed to save user ${user.username}.`, 'error'));
    },
    deleteUser(username) {
      this.$store.dispatch("users/delete", username)
        .then(() => this.showSnackbar(`User ${username} deleted successfully!`, 'success'))
        .catch(() => this.showSnackbar(`Failed to delete user ${username}.`, 'error'));
    },
    showSnackbar(text, status = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = status === 'success' ? 'green-lighten-1' : 'red darken-1';
      this.snackbar.show = true;
    },
  },
};
</script>

<style></style>
