import TeamService from '../../services/team.service.js';

const storagedTeams = JSON.parse(localStorage.getItem('teams') || null);
const initialState = {
    team_list: storagedTeams
}

export const teams = {
    namespaced: true,
    state: initialState,
    actions: {
        getAll({ commit }) {
            return TeamService.getAllTeams()
                .then(async teams => {
                    commit('getAllSuccess', teams);
                    return Promise.resolve(teams);
                },
                    async error => {
                        commit('getAllFailure');
                        return Promise.reject(error);
                    })
        },
        add({ commit }, name) {
            return TeamService.addTeam(name)
                .then(async response => {
                    commit('addSuccess', response);
                    return Promise.resolve(response);
                },
                    async error => {
                        commit('addFailure');
                        return Promise.reject(error);
                    })
        },
        delete({ commit }, name) {
            return TeamService.deleteTeam(name)
                .then(async response => {
                    commit('deleteSuccess', name);
                    return Promise.resolve(response);
                },
                    async error => {
                        commit('deleteFailure');
                        return Promise.reject(error);
                    })
        },
        saveSelection({ commit }, { teamId, selectedSIMs }) {
            return TeamService.saveSelection(teamId, selectedSIMs)
                .then(async response => {
                    commit('saveSelectionSuccess', { teamId, selectedSIMs });
                    return Promise.resolve(response);
                },
                    async error => {
                        commit('saveSelectionFailure');
                        return Promise.reject(error);
                    })
        },
    },
    mutations: {
        getAllSuccess(state, teams) {
            state.team_list = teams;
            localStorage.setItem('teams', JSON.stringify(teams));
        },
        getAllFailure() {
            console.log('get all teams failure');
        },
        addSuccess(state, payload) {
            state.team_list.push(payload.team);
        },
        addFailure() {
            console.log('add team failure');
        },
        deleteSuccess(state, name) {
            const index = state.team_list.findIndex(team => team.name === name);
            if (index !== -1) {
                state.team_list.splice(index, 1);
            }
        },
        deleteFailure() {
            console.log('delete team failure');
        },
        saveSelectionSuccess(state, { teamId, selectedSIMs }) {
            const team = state.team_list.find(team => team.id === teamId);
            if (team) {
                team.sims = selectedSIMs;
            }
            localStorage.setItem('teams', JSON.stringify(state.team_list));
        },
        saveSelectionFailure() {
            console.log('save selection failure');
        },
    }
};
