import BACKEND_URL from './api.service.js';

class TeamService {
  
  // Общий метод для выполнения запросов с обработкой ошибок
  async performRequest(method, url, data = {}) {
    try {
      const response = await BACKEND_URL[method](url, data);
      return response?.data;
    } catch (error) {
      console.error(`Request failed: ${method.toUpperCase()} ${url}`, error);
      throw new Error(error?.response?.data?.message || 'Request failed');
    }
  }

  /**
   * Получение списка всех команд
   * @returns {Promise<Array>} - Возвращает массив команд
   */
  getAllTeams() {
    return this.performRequest('get', '/api/v1/team/getall')
      .then(data => data?.teams || []);
  }

  /**
   * Добавление новой команды
   * @param {string} name - Имя команды
   * @returns {Promise<Object>} - Возвращает объект новой команды
   */
  addTeam(name) {
    return this.performRequest('post', '/api/v1/team/add', { name });
  }

  /**
   * Удаление команды
   * @param {string} name - Имя команды для удаления
   * @returns {Promise<Object>} - Возвращает результат удаления
   */
  deleteTeam(name) {
    return this.performRequest('post', '/api/v1/team/delete', { name });
  }

  /**
   * Сохранение выбранных SIM-карт для конкретной команды
   * @param {number} teamId - ID команды
   * @param {Array} selectedSIMs - Массив выбранных SIM-карт
   * @returns {Promise<Object>} - Возвращает результат сохранения
   */
  saveSelection(teamId, selectedSIMs) {
    return this.performRequest('post', '/api/v1/team/updateports', {
      teamId,
      selectedSIMs
    });
  }
}

export default new TeamService();
